import { AxiosPromise } from 'axios';
import { format as dateFnsFormat } from 'date-fns';

import { ORDER_SERVICE, axiosInstance } from '@app/adapter/axios';
import { ProductCategoryKey } from '@app/types/catalog';
import { GetListDataRequest, Paginated } from '@app/types/common';
import {
  Order,
  OrderStatus,
  OrderStats,
  OrderStatusKey,
  OrderTypeKey,
  OrderUpdate,
} from '@app/types/order';
import { filterSyntaxGen } from '@app/utils';
import { convertUtcToJp } from '@app/utils/format';

/**
 * Order
 */
export function getOrdersByOrgId(
  organizationId?: string,
  options?: Partial<
    GetListDataRequest & {
      filter: {
        billingDateFrom?: string;
        billingDateTo?: string;
        createdAtFrom?: string;
        createdAtTo?: string;
        keyword?: string;
        orderType?: OrderTypeKey | OrderTypeKey[];
        statuses?: OrderStatusKey | OrderStatusKey[];
      };
    }
  >
): AxiosPromise<Paginated<Order>> {
  if (options?.nextLink || options?.previousLink) {
    return axiosInstance
      .get(options.nextLink || options.previousLink || '')
      .catch((error) => {
        if ('message' in error.response.data) {
          throw new Error(error.response?.data.message);
        } else {
          throw new Error(error.message);
        }
      });
  }

  const pageSize = options?.pageSize || 10;
  const page = options?.page || 0;
  const urlParams = [
    ['$top', pageSize.toString()],
    ['$skip', (page * pageSize).toString()],
  ];

  const filterParam = [];
  if (options?.filter?.orderType?.length) {
    const orderTypes = Array.isArray(options.filter.orderType)
      ? options.filter.orderType
      : [options.filter.orderType];
    filterParam.push(
      `customFields.orderType in ${filterSyntaxGen(orderTypes)}`
    );
  }
  if (options?.filter?.statuses?.length) {
    const statuses = Array.isArray(options.filter.statuses)
      ? options.filter.statuses
      : [options.filter.statuses];
    filterParam.push(`status in ${filterSyntaxGen(statuses)}`);
  }
  if (options?.filter?.createdAtFrom) {
    filterParam.push(
      `ymd(createdAt) ge '${dateFnsFormat(
        convertUtcToJp(options.filter.createdAtFrom),
        'yyyy-MM-dd'
      )}'`
    );
  }
  if (options?.filter?.createdAtTo) {
    filterParam.push(
      `ymd(createdAt) le '${dateFnsFormat(
        convertUtcToJp(options.filter.createdAtTo),
        'yyyy-MM-dd'
      )}'`
    );
  }

  // 請求締日による期間指定
  const billingDateFilters = [];
  if (options?.filter?.billingDateFrom) {
    billingDateFilters.push(
      `ymd(updatedAt) ge '${dateFnsFormat(
        convertUtcToJp(options.filter.billingDateFrom),
        'yyyy-MM-dd'
      )}'`
    );
  }
  if (options?.filter?.billingDateTo) {
    billingDateFilters.push(
      `ymd(updatedAt) le '${dateFnsFormat(
        convertUtcToJp(options.filter.billingDateTo),
        'yyyy-MM-dd'
      )}'`
    );
  }
  if (billingDateFilters.length) {
    billingDateFilters.push(
      `status in ${filterSyntaxGen([
        OrderStatus.ACCEPTED,
        OrderStatus.CANCELED,
      ])}`
    );
    filterParam.push(
      `((${billingDateFilters.join(' and ')}) or status eq '${
        OrderStatus.PROCESSING
      }')`
    );
  }

  if (options?.filter?.keyword) {
    const queryFields = [
      'customer.name',
      'customer.email',
      'customer.phone',
      'lineItems.productName',
    ];
    const query = queryFields
      .map((field) => `${field} co '${options.filter?.keyword}'`)
      .join(' or ');
    filterParam.push(`(${query})`);
  }
  if (filterParam.length > 0) {
    urlParams.push(['$filter', filterParam.join(' and ')]);
  }

  if (options?.expand) {
    urlParams.push(['$expand', options.expand]);
  }
  urlParams.push(['$orderBy', options?.orderBy || 'createdAt desc']);

  return axiosInstance
    .get(
      `${ORDER_SERVICE}/orgs/${organizationId}/orders?${new URLSearchParams(
        urlParams
      ).toString()}`
    )
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function getOrder(
  organizationId: string,
  orderId: string,
  options?: {
    expand?: string;
  }
): AxiosPromise<Order> {
  const urlParams: string[][] = [];
  if (options?.expand) {
    urlParams.push(['$expand', options.expand]);
  }
  return axiosInstance
    .get(
      `${ORDER_SERVICE}/orgs/${organizationId}/orders/${orderId}?${new URLSearchParams(
        urlParams
      ).toString()}`
    )
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function updateOrder(
  organizationId: string,
  orderId: string,
  order: OrderUpdate
): AxiosPromise<void> {
  return axiosInstance
    .patch(`${ORDER_SERVICE}/orgs/${organizationId}/orders/${orderId}`, order)
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function deleteOrder(
  organizationId: string,
  selectedId: string
): AxiosPromise<void> {
  return axiosInstance
    .patch(`${ORDER_SERVICE}/orgs/${organizationId}/orders/${selectedId}`, {
      status: OrderStatus.CANCELED,
    })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function getOrderStats(
  organizationId: string,
  productIds: string[]
): AxiosPromise<OrderStats[]> {
  const params = new URLSearchParams();

  if (productIds && productIds.length > 0) {
    params.append('productIds', productIds.join(','));
  }

  return axiosInstance
    .get(
      `${ORDER_SERVICE}/orgs/${organizationId}/products/stats/orders?${params}`
    )
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function updateOrderStatus(
  organizationId: string,
  selectedId: string,
  status: OrderStatusKey
): AxiosPromise<void> {
  return axiosInstance
    .patch(`${ORDER_SERVICE}/orgs/${organizationId}/orders/${selectedId}`, {
      status,
    })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

/**
 * CSVファイル情報を取得
 */
export function getCsvFile(options?: {
  createdAt?: string[]; //0:~から, 1:~まで
  orderBy?: string;
  orderType?: string;
  orgId?: string;
  productCategory?: ProductCategoryKey;
  status?: OrderStatusKey | OrderStatusKey[];
}): AxiosPromise<string> {
  const urlParams: string[][] = [];

  const filterParam = [];
  if (options?.orderType) {
    filterParam.push(`customFields.orderType eq '${options.orderType}'`);
  }
  if (options?.status?.length) {
    const status = Array.isArray(options.status)
      ? options.status
      : [options.status];
    filterParam.push(`status in ${filterSyntaxGen(status)}`);
  }
  if (options?.createdAt) {
    if (options.createdAt[0]) {
      filterParam.push(
        `ymd(createdAt) ge '${dateFnsFormat(
          convertUtcToJp(options.createdAt[0]),
          'yyyy-MM-dd'
        )}'`
      );
    }
    if (options.createdAt[1]) {
      filterParam.push(
        `ymd(createdAt) le '${dateFnsFormat(
          convertUtcToJp(options.createdAt[1]),
          'yyyy-MM-dd'
        )}'`
      );
    }
  }
  if (filterParam.length > 0) {
    urlParams.push(['$filter', filterParam.join(' and ')]);
  }

  if (options?.orgId) {
    urlParams.push(['orgId', options.orgId]);
  }
  if (options?.productCategory) {
    urlParams.push(['productCategory', options.productCategory]);
  }

  urlParams.push(['$orderBy', options?.orderBy || 'createdAt desc']);

  return axiosInstance
    .get(
      `${ORDER_SERVICE}/csv/orders?${new URLSearchParams(urlParams).toString()}`
    )
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function getGCPUploadUrl(
  orgId: string,
  contentLength: number
): AxiosPromise<string> {
  return axiosInstance
    .get(`${ORDER_SERVICE}/orgs/${orgId}/csv-upload-url`, {
      params: {
        contentLength,
      },
    })
    .catch((error) => {
      if ('message' in error.response.data) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error.message);
      }
    });
}

export function importCsvFile(
  orgId: string,
  csvFileId: string,
  orderType: OrderTypeKey
): AxiosPromise<string> {
  return axiosInstance.post(`${ORDER_SERVICE}/orgs/${orgId}/import-order`, {
    csvFileId,
    orderType,
  });
}
