import { endOfMonth, isAfter } from 'date-fns';

import {
  Organization,
  OrganizationStatus,
  OrganizationStatusKey,
} from '@app/types/organization';

export function getStatusLabel(status?: OrganizationStatusKey): string {
  switch (status) {
    case OrganizationStatus.NORMAL:
      return '利用中';
    case OrganizationStatus.CANCELLED:
      return '利用停止';
    default:
      return '-';
  }
}

export const isStoppedOrganization = (status: string) => {
  return status === OrganizationStatus.CANCELLED;
};

/**
 * 反響の月の締日を取得する
 */
export const getBillingEndDate = (
  organization: Organization | null,
  date = new Date()
): Date => {
  const result = endOfMonth(date);
  const billingEndDate = organization?.customFields?.monthlyBillingEndDate;
  if (billingEndDate) {
    result.setDate(Number(billingEndDate));
  }
  if (isAfter(date, result)) {
    result.setMonth(result.getMonth() + 1);
  }
  return !billingEndDate ? endOfMonth(result) : result;
};
