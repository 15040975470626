import { addDays, addMonths, endOfMonth, isAfter, startOfDay } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { getOrdersByOrgId } from '@app/adapter/order-service';
import { organizationSelector } from '@app/domain/organization';
import { ProductCategory, ProductCategoryKey } from '@app/types/catalog';
import { OrderStatus, OrderType, OrderTypeKey } from '@app/types/order';
import { isError } from '@app/utils/error';

export const useOrderAlert = (category?: ProductCategoryKey) => {
  const organizationState = useRecoilValue(organizationSelector);

  const [count, setCount] = useState(0);
  const fetchOrderAlert = useCallback(async () => {
    if (!organizationState) return;
    try {
      const now = startOfDay(new Date());
      let billingDate = startOfDay(endOfMonth(now));
      if (organizationState.customFields?.monthlyBillingEndDate) {
        billingDate.setDate(
          Number(organizationState.customFields.monthlyBillingEndDate)
        );
      }
      if (isAfter(now, billingDate)) {
        billingDate = addMonths(billingDate, 1);
        if (!organizationState.customFields?.monthlyBillingEndDate) {
          billingDate = startOfDay(endOfMonth(billingDate));
        }
      }
      if (isAfter(billingDate, addDays(now, 7))) {
        return;
      }

      const orderTypes = [];
      switch (category) {
        case ProductCategory.EXHIBITION:
          orderTypes.push(OrderType.EXHIBITION_REQUEST_DOC);
          orderTypes.push(OrderType.EXHIBITION_VISITOR_RESERVE);
          break;
        case ProductCategory.PROPERTY:
          orderTypes.push(OrderType.PROPERTY_REQUEST_DOC);
          orderTypes.push(OrderType.PROPERTY_VISITOR_RESERVE);
          break;
      }

      const { data } = await getOrdersByOrgId(organizationState.id, {
        filter: {
          orderType: orderTypes as OrderTypeKey[],
          statuses: OrderStatus.PROCESSING,
        },
        pageSize: 1,
      });
      setCount(data.total);
    } catch (e) {
      if (isError(e)) {
        console.error(e.message);
      }
    }
  }, [organizationState, category]);

  useEffect(() => {
    void fetchOrderAlert();
  }, [fetchOrderAlert]);

  return {
    count,
    fetchOrderAlert,
  };
};
